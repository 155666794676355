import React from 'react';
import Menu from '../Menu';
import history from '../../../history';

export default function Header() {
  return (
    <div>
      <header>
        <div className="logo" onClick={() => history.push('/home')}>
          <img
            src={`${process.env.PUBLIC_URL}/dt-logo2.png`}
            alt="Logotipo DataSystem"  
          />
        </div>
        <div className="header-action">
          <Menu />
        </div>
      </header>
    </div>
  );
}
