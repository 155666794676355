// @flow
import * as React from 'react';
import './style.css';
import history from '../../history';

export const notFound = () => {
  return (
    <>
      <header>
        <div className="logo">
          <img src="/dt-logo2.png" alt="Logotipo DataSystem" />
        </div>
      </header>
      <div className="container-notFound">
        <img src="/notFound.svg" alt="Página não encontrada." />
        <div>404 Página não encontrada</div>
      </div>
      <div className="action-back-button">
        <button
          className="btn btn-success"
          onClick={() => history.push('/installments')}
        >
          Voltar
        </button>
      </div>
    </>
  );
};
