import React, { useState, useEffect } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Dropdown, Accordion } from 'react-bootstrap';
import Skeleton from '../../Components/Skeleton/Skeleton';
import { Carregando } from '../../Components/Carregando/Carregando';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { download } from '../../utils/download';
import DsModal from '../../Components/Modal/index';

import api, { key } from '../../api';
import Header from '../../Components/Base/Header';

const Installments = () => {
  const [installments, setInstallments] = useState([]);
  const [show, setShow] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const [itemSegundaVia, setItemSegundaVia] = useState({
    LINHADIGITAVEL: 0,
    MAILPAYORDER: 'exemple@mail.com',
  });

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const mailPayment = localStorage.getItem('mail');
  const nameUser = localStorage.getItem('name');

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      const { data } = await api.post(
        `ftapi/v1/customer-portal/financial/pending-installments/${key}`,
      );
      setInstallments(data[0].data);
    })();
  }, []);

  //#region [Function]
  function moeda(valor) {
    if (valor) {
      return valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return '';
  }
  //#endregion

  //#region [Handles]

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSegundaVia = (item) => {
    setItemSegundaVia(item);
    handleShow();
  };

  const handleConfirmar = () => {
    setLoading(true);
    api
      .post(
        `ftapi/v1/customer-portal/financial/boleta/sendmail/${key}/${itemSegundaVia.NUFIN}`,
      )
      .then((item) => {
        setMessage(item.data.data.message);
        setIsSend(true);
        handleClose();
      })
      .catch((e) => {
        alert(e.response.data.error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setLoading(true);
    api
      .post(
        `ftapi/v1/customer-portal/financial/boleta/print/${key}/${itemSegundaVia.NUFIN}`,
        {},
        { responseType: 'blob' },
      )
      .then((item) => {
        download(item.data, `${nameUser} - ${itemSegundaVia.NUFIN}`, true);
      })
      .catch((e) => {
        alert(
          'Não foi possível gerar o boleto. Favor entrar em contato com o setor financeiro.',
        );
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleCancelar = () => {
    handleClose();
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  //#endregion

  return (
    <>
      <DsModal
        key={Math.random()}
        title={{ text: 'Enviado com sucesso', variant: 'bg-success' }}
        message={message}
        onClose={setIsSend}
        isOpen={isSend}
        buttons={[{ text: 'Ok', variant: 'success', action: setIsSend }]}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Segunda via do boleto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Carregando isVisible={loading} />
            {!loading && (
              <>
                <div className="modal-content-header">
                  <p>Linha Digitável para Pagamento:</p>
                </div>
                <p>
                  <center>
                    <b>{itemSegundaVia.LINHADIGITAVEL}</b>
                  </center>
                </p>
                <div className="modal-content-body">
                  <p>
                    Caso o e-mail registrado não seja o seu, entre em contato
                    para atualizar seu cadastro.
                  </p>
                  <p>
                    Enviar segunda via do boleto para o E-mail: {mailPayment}
                  </p>
                  <p>
                    <strong className="color-red">AVISO:</strong> O download
                    será iniciando em outra janela, para prosseguir realize o
                    desbloqueio de pop-up do navegador!
                  </p>
                </div>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOpen} disabled={loading}>
            Baixar
          </Button>
          <Button
            variant="success"
            onClick={handleConfirmar}
            disabled={loading}
          >
            Enviar
          </Button>
          <Button variant="danger" onClick={handleCancelar} disabled={loading}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <div className="container-full">
        <div className="content">
          <div className="content-title">
            <div>
              Prestações <b>Pendentes</b> ({nameUser})
            </div>
          </div>
          {!installments ? (
            <>
              <div className="content-body content-item-paid">
                <p> Nenhum título pendente encontrado! </p>
              </div>
            </>
          ) : !installments.length ? (
            <Skeleton />
          ) : (
            <>
              <div className="content-body">
                <div className="content-list">
                  {/* <div>{installments[0].ITENS}</div> */}
                  {installments.map((item, index) => {
                    const dataVenc = item.DTVENC.substring(10, -10);
                    const atraso =
                      item.ATRASO === 1
                        ? `${item.ATRASO} dia`
                        : `${item.ATRASO} dias`;
                    return (
                      <Accordion key={index}>
                        <Accordion.Item eventKey="0" id="accordion">
                          <Accordion.Header>
                            <div className="installment-item">
                              <div key={item.NUFIN}>
                                <strong>Número Único</strong> {item.NUFIN}
                              </div>
                              <div key={item.PARCELA}>
                                <strong>Parcela</strong> {item.PARCELA}
                              </div>
                              <div key={dataVenc}>
                                <strong>Data Venc.</strong> {dataVenc}
                              </div>
                              <div className={item.ATRASO <= 0 
                                                      ? 'color-blue'
                                                      : 'color-red'}>
                                <strong>Atraso</strong> {atraso}
                              </div>
                              <div>
                                <strong>Valor</strong>{' '}
                                {moeda(Number(item.VALOR))}
                              </div>
                            </div>
                            <div onClick={handlePropagation}>
                              {
                                <Dropdown id="dropdownBtn">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    drop="none"
                                    disabled={item.ACAO === ''}
                                  >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {item.ACAO.split(',').map((acao) => {
                                      return (
                                        acao === 'Segunda Via' && (
                                          <Dropdown.Item
                                            key={acao}
                                            onClick={() =>
                                              handleSegundaVia(item)
                                            }
                                          >
                                            {' '}
                                            {acao}
                                            {/*acao === 'RENEGOCIA' 
                                                      ? 'Renegociar'
                                                      : 'Segunda via'*/}
                                          </Dropdown.Item>
                                        )
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              }
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.DETALHE }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="whatsapp-fab">
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5519111111111"
          rel="noreferrer"
        >
          <img
            src={`${process.env.PUBLIC_URL}/whatsapp.png`}
            alt="Ícone de whatsapp para enviar uma mensagem"
          />
        </a>
      </div>
    </>
  );
};

export default Installments;
