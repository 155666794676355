export function download(data, fileName, abrir = false) {
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.download = 'file.pdf';
  link.className = 'display-none';
  link.href = fileURL;
  link.target = '_blank';
  if (abrir) {
    window.open(fileURL, '_blank');
    return;
  }
  link.setAttribute('Open', fileName); // Added Line
  link.download = fileName;
  link.dispatchEvent(new MouseEvent('click'));
}
