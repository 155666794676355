import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DsModal = ({ isOpen, title, message, buttons, onClose }) => {
  return (
    <Modal show={isOpen}>
      <Modal.Header className={title.variant} closeButton>
        <Modal.Title className="text-white text-center">
          {title.text}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{message}</Modal.Body>

      <Modal.Footer>
        {buttons.map((button, index) => {
          return (
            <Button key={index} variant={button.variant} onClick={() => button.action()}>
              {button.text}
            </Button>
          );
        })}
      </Modal.Footer>
    </Modal>
  );
};

export default DsModal;
