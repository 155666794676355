import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faHandHoldingUsd,
  faDollarSign,
  faHome,
  faDoorClosed,
} from '@fortawesome/free-solid-svg-icons';
import history from '../../../history';
import { Context } from '../../../Context/AuthContext';

export default function Menu() {
  const { handleLogout } = useContext(Context);

  const handleSair = () => {
    if (window.confirm('Realmente deseja sair?')) handleLogout();
  };

  return (
    <div>
      <div className="">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" drop="none">
            <FontAwesomeIcon icon={faBars} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => history.push('/home')}>
              <div className="d-flex justify-content-between">
                <div>Home</div>
                <FontAwesomeIcon icon={faHome} color="#0000FF" />
              </div>
            </Dropdown.Item>
            <div className="dropdown-divider"></div>
            <Dropdown.Item onClick={() => history.push('/installments')}>
              <div className="d-flex justify-content-between">
                <div>Títulos Pendentes</div>
                <FontAwesomeIcon icon={faHandHoldingUsd} color="#dc3545" />
              </div>
            </Dropdown.Item>
            <div className="dropdown-divider"></div>
            <Dropdown.Item onClick={() => history.push('/installmentsPaid')}>
              <div className="d-flex justify-content-between">
                <div>Títulos Pagos</div>
                <FontAwesomeIcon icon={faDollarSign} color="#198754" />
              </div>
            </Dropdown.Item>
            <div className="dropdown-divider"></div>
            <Dropdown.Item onClick={() => handleSair()}>
              <div className="d-flex justify-content-between">
                <div>Sair</div>
                <FontAwesomeIcon icon={faDoorClosed} color="#FFDD00" />
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
