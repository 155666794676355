import React, { useState, useEffect } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Skeleton from '../../Components/Skeleton/Skeleton';
import { Accordion } from 'react-bootstrap';
import history from '../../history';

import api, { key } from '../../api';
import Header from '../../Components/Base/Header';

const Installments = () => {
  const [installments, setInstallments] = useState([]);

  const name = localStorage.getItem('name');

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      const { data } = await api.post(
        `ftapi/v1/customer-portal/financial/paid-installments/${key}`,
      );
      setInstallments(data[0].data);
    })();
  }, []);

  //#region [Function]
  function moeda(valor) {
    if (valor) {
      return valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return '';
  }
  //#endregion

  return (
    <>
      <Header />
      <div className="container-full">
        <div className="content">
          <div className="content-title">
            <div>
              Prestações <b>Pagas</b> ({name})
            </div>
          </div>
          {!installments ? (
            <div className="content-body content-item-paid">
              <p> Nenhum título pago encontrado! </p>
            </div>
          ) : !installments.length ? (
            <Skeleton />
          ) : !installments.length ? (
            <Skeleton />
          ) : (
            <>
              <div className="content-body">
                <div className="content-list">
                  {installments.map((item, index) => {
                    const dataVenc = item.DTVENC.substring(10, -10);
                    return (
                      <Accordion key={index}>
                        <Accordion.Item eventKey="0" id="accordion">
                          <Accordion.Header>
                            <div className="content-item-paid" key={item.NUFIN}>
                              <div className="installment-item-pagas">
                                <div>
                                  <strong>Número Único</strong> {item.NUFIN}
                                </div>
                                <div>
                                  <strong>Parcela</strong> {item.PARCELA}
                                </div>
                                <div>
                                  <strong>Data Venc.</strong> {dataVenc}
                                </div>
                                <div>
                                  <strong>Valor</strong>{' '}
                                  {moeda(Number(item.VALOR))}
                                </div>
                                <div>
                                  <strong>Pago</strong>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/pago.png`}
                                    alt="Ícone de whatsapp para enviar uma mensagem"
                                    width="32"
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.DETALHE }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="action-back-button">
        <button
          className="btn btn-success"
          onClick={() => history.push('/installments')}
        >
          Voltar
        </button>
      </div>
      <div className="whatsapp-fab">
        <a href="https://api.whatsapp.com/send?phone=5519111111111">
          <img
            src={`${process.env.PUBLIC_URL}/whatsapp.png`}
            alt="Ícone de whatsapp para enviar uma mensagem"
          />
        </a>
      </div>
    </>
  );
};

export default Installments;
