import React, { useState, useEffect } from 'react';
import Header from '../../Components/Base/Header';
import api, { key } from '../../api';
import './style.home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingUsd,
  faDollarSign,
  faCheck,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import history from '../../history';
import Skeleton from '../../Components/Skeleton/Skeleton';
export default function Home() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const handleConfirmMessage = (nu) => {
    api
      .put(
        `ftapi/v1/customer-portal/messages/${key}/${nu}`,
      )
      .catch((e) => console.log('error:', e))
      .finally(() => {
        setMessages([]);
      });
  };

  useEffect(() => {
    setIsloading(true);
    (async () => {
      const token = localStorage.getItem('token');
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      const { data } = await api.get(
        `ftapi/v1/customer-portal/messages/${key}`,
      );
      if(data[0].data!= null) {
        const alertas = data[0].data;
        setMessages(alertas);
      }
    })().finally(() => {
      setIsloading(false)
    });
  }, []);

  

  return (
    <>
      <Header />
      <div className="container-home">
        <h1>Bem vindo ao portal do cliente</h1>
        <div className="main">
          <div></div>
          <div className="main-left">
            <div className='fw-bold'><h2>{localStorage.getItem('name')}</h2></div>
            <div className='main-img'></div>
            <div className='main-buttons'>
              <div className='container-buttons'>
                <button onClick={() => history.push('/installments')}>
                  <div><FontAwesomeIcon icon={faDollarSign} /></div>
                  <div>Titulos Pendentes</div>
                </button >
                <button onClick={() => history.push('/installmentsPaid')}>
                  <div><FontAwesomeIcon icon={faHandHoldingUsd} /></div>
                  <div>Titulos Pagos</div>
                </button>
                <button onClick={() => history.push('/Notas')}>
                  <div><FontAwesomeIcon icon={faPrint} /></div>
                  <div>NF-e/NFS-e</div>
                </button>
                {/* <button>
                <div><FontAwesomeIcon icon={faDoorClosed} /></div>
                  <div>Sair</div>
                </button> */}
              </div>
            </div>
          </div>
          <div className="main-center">
            <h1>Avisos</h1>
            {isLoading ? (
              <Skeleton rowHeight={70} rows={7}/>
            ): (
              <div className="list-group">
                <div className="list-wrapper p-2">
                  {messages.length ? messages.map((item, index) => {
                    return (
                    <div key={index} className="list-group-item mb-3 card-message">
                      <div className='text-text-black'>
                        <FontAwesomeIcon icon={faCheck} className='text-success' />
                        <div className='d-inline m-2'>
                          {item.JAVISUALIZADO === 'S' && (
                            <small>Visualizado em: {item.VISUALIZADOEM}</small>
                          )}
                        </div>
                      </div>
                      <div className='mb-4 card-text p-2'>{item.AVISO}</div>
                      <div className={`${item.JAVISUALIZADO !== 'S'} text-left`}>
                        {item.JAVISUALIZADO !== 'S' && (
                          <button onClick={() => handleConfirmMessage(item.NUNICO)} className='btn btn-success'>Marcar como Lido</button>
                        )}
                      </div>
                    </div>);
                  }) : (
                    <div className='text-center text-success'>Nenhum alerta encontrado</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
