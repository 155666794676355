import { useState, useEffect } from 'react';

import api, { key } from '../../api';
import history from '../../history';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token && token !== 'undefined') {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  async function handleLogin(request) {
    setLoading(true);
    await api
      .post(`ftapi/v1/customer-portal/authenticate/${key}`, request)
      .then((item) => {
        const { data } = item;
        localStorage.setItem('token', JSON.stringify(data.data.token));
        localStorage.setItem('name', data.data.user.companyName);
        localStorage.setItem('mail', data.data.user.mail);
        api.defaults.headers.Authorization = `Bearer ${data.data.token}`;
        setAuthenticated(true);
        history.push('/home');
      })
      .catch((e) => {
        console.log(e);
        // const { data: error } = e.response;
        alert('Falha ao autenticar');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    history.push('/');
  }

  return { authenticated, loading, handleLogin, handleLogout };
}
