import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from './Context/AuthContext';
import Login from './pages/Login/Login.jsx';
import Home from './pages/Home/index';
import Installments from './pages/Installments/index';
import InstallmentsPaid from './pages/InstallmentsPaid/';
import Notas from './pages/Notas/index';
import Loading from './pages/Loading/Loading';
import { notFound } from './pages/NotFound/index';

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <Loading />;
  }

  if (isPrivate && !authenticated) {
    sessionStorage.removeItem('token');
    return <Redirect to="/login" />;
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={Login} />
      <CustomRoute isPrivate exact path="/home" component={Home} />
      <CustomRoute
        isPrivate
        exact
        path="/installments"
        component={Installments}
      />
      <CustomRoute
        isPrivate
        exact
        path="/InstallmentsPaid"
        component={InstallmentsPaid}
      />
      <CustomRoute
        isPrivate
        exact
        path="/Notas"
        component={Notas}
      />
      <Route component={notFound} />
      <Route exact path="/" component={Login} />
    </Switch>
  );
}
