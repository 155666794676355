import React from "react";
import ReactInputMask from "react-input-mask";

export default function DocNumber({ value, onChange, ...props }) {
  let [mask, setMask] = React.useState(null);
  
  const handleMask = (ev) => {
    let charCode = String.fromCharCode(ev.which).toLowerCase();
    var er = /[^a-z0-9]/gi;
    if((ev.ctrlKey || ev.metaKey) && charCode === 'v') {
      setTimeout(() => {
        const formattedPaste = ev.target.value.replace(er, '');
        if(formattedPaste.length === 14) {
          setMask('99.999.999/9999-99');
          return;
        } else {
          setMask('999.999.999-99');
          return;
        }
        }, 1000)
    }
    const { value } = ev.target;

    
    const formatted = value.replace(er, '');

    let range = 0;

    if(Number.isInteger(ev.key.length))
      range = formatted.length + ev.key.length;

    if(range > 11 ) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-99');
    }
  }
  return (
      <ReactInputMask
        mask={mask}
        onChange={ev => onChange(ev)}
        onKeyDown={handleMask}
        maskChar="_"
        value={value}
        {...props}
      />
  );
}
