import React from 'react';
import './style.css';
import { Carregando } from '../../Components/Carregando/Carregando';

const Loading = () => {
  return (
    <div className="loading">
      <div className="spinner">
        <Carregando isVisible />
      </div>
      <p>Carregando Página ...</p>
    </div>
  );
};

export default Loading;
