import React, { useContext, useState } from 'react';
import './style.css';
import { Context } from '../../Context/AuthContext';
import { ptBR } from 'date-fns/locale'; 
import { Carregando } from '../../Components/Carregando/Carregando';
import { registerLocale } from  "react-datepicker";
import DocNumber from '../../Components/InputDocument/index'

import "react-datepicker/dist/react-datepicker.css";


export default function Login() {
  const { handleLogin, loading } = useContext(Context);
  registerLocale('pt-BR', ptBR)

  const [cpfCnpj, setCpfCnpj] = useState('');
  const [codigoCli, setCodigoCli] = useState();

  //#region [function]
  function handleDocument(doc) {
    var er = /[^a-z0-9]/gi;
    const formatted = doc.replace(er, '');
    return formatted;
  }

  function validate() {
    const onlyNumbers = /^[0-9]+$/.test(Number(codigoCli));
    
    if(!document) {
      alert('Documento é um campo obrigatório. Por favor preencha seu CPF ou CNPJ.');
      return false;
    }

    if (!codigoCli) {
      alert('Código do Cliente é obrigatório');
      return false;
    }
    if (!onlyNumbers) {
      alert('O Campo Código do cliente deve conter apenas números');
      return false;
    }

    return true;
  }
  //#endregion

  //#region [Handle]
  async function handlePageLogin () {

    if (codigoCli && document && validate()) {
      const infoRequest = {
        authenticationType: 'parceiro',
        codparc: codigoCli,
        document: handleDocument(cpfCnpj)
      }
      handleLogin(infoRequest);
      return;
    }
  };
  //#endregion

  return (
    <div className="container-login">
      <div className="container-wrapper">
        <div className="container-logo">
          <img
            src={`${process.env.PUBLIC_URL}/Logo-Data-System.png`}
            alt="Logotipo da empresa DataSystem"
            width="100%"
            height="auto"
          />
        </div>
        <div>
          <div className="container-form">
            <div className="mb-3">
              <strong>Código do Cliente</strong>
              <input
                type="text"
                name="codigo-cliente"
                maxLength={8}
                className="form-control"
                placeholder="Digite o código cliente"
                onChange={(el) => setCodigoCli(el.target.value)}
              />
            </div>

            <div className="mb-3">
              <strong>Número do documento</strong>
              <DocNumber
                className="form-control"
                value={cpfCnpj}
                onChange={(event) => {
                  setCpfCnpj(event.target.value);
                }}
                type="tel"
                name="codCli"
                id="codCli"
                placeholder="Digite seu CPF ou CNPJ"
              />
            </div>
            {loading ? (
              <Carregando isVisible={loading} />
            ) : (
              <div className="container-action">
                <button onClick={handlePageLogin} type="submit">
                  Entrar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="whatsapp-fab">
        <a href="https://api.whatsapp.com/send?phone=5519911111111">
          <img
            src={`${process.env.PUBLIC_URL}/whatsapp.png`}
            alt="Ícone de whatsapp para enviar uma mensagem"
          />
        </a>
      </div>
    </div>
  );
}
