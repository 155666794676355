// @flow
import * as React from 'react';
import './style.css';

export const Carregando = ({ isVisible }) => {
    const carregando = isVisible ? 'display-block' : 'display-none';
    return (
        <div>
            <div className={`lds-ring ${carregando}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
