import React, { useState, useEffect } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Skeleton from '../../Components/Skeleton/Skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { download } from '../../utils/download';
// import DsModal from '../../Components/Modal/index';

import api, { key } from '../../api';
import Header from '../../Components/Base/Header';

const Notas = () => {
  const [notas, setnotas] = useState([]);
  // const [isSend, setIsSend] = useState(false);

  const [itemnotas, setitemnotas] = useState({});

  // const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const nameUser = localStorage.getItem('name');

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      const { data } = await api.get(`ftapi/v1/customer-portal/notas/${key}`);
      setnotas(data.data);
    })();
  }, []);

  //#region [Function]
  function moeda(valor) {
    if (valor) {
      return valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return '';
  }
  //#endregion

  //#region [Handles]
  const handlenotas = (item) => {
    setitemnotas(item);
    console.log(item)
    handleDownload(item);
  };

  const handleDownload = (nota) => {
    setLoading(true);
    api
      .post(
        `ftapi/v1/customer-portal/relatorios/visualizar/${key}/${nota.TIPO ==='NF-e' ? 39 : 24}`,
      {NUNOTA:`${nota.NUNOTA}`
      },
        { responseType: 'blob' },
      )
      .then((item) => {
        console.log(item)
        download(item.data, `${nameUser} - ${itemnotas.NUFIN}`, true);
      })
      .catch((e) => {
        alert(
          'Não foi possível imprimir. Favor entrar em contato com o setor financeiro.',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  //#endregion

  return (
    <>
      {/* <DsModal
        key={Math.random()}
        title={{ text: 'Enviado com sucesso', variant: 'bg-success' }}
        message={message}
        onClose={setIsSend}
        isOpen={isSend}
        buttons={[{ text: 'Ok', variant: 'success', action: setIsSend }]}
      /> */}

      <Header />
      <div className="container-full">
        <div className="content">
          <div className="content-title">
            <div>
            NF-e/NFS-e <b>Emitidas</b> ({nameUser})
            </div>
          </div>
          {!notas ? (
            <>
              <div className="content-body content-item-paid">
                <p> Nenhuma Nota encontrada! </p>
              </div>
            </>
          ) : notas.length === 0 || loading ? (
            <Skeleton />
          ) : (
            <>
              <div className="content-body">
                <div className="content-list">
                  {notas.map((item, index) => {
                    const dataEmissao = item.DTNEG.substring(10, -10);
                    return (
                      <div key={index}>
                        <div className="installment-item py-2">
                          <div key={item.TIPO}>
                            <strong>Tipo</strong> {item.TIPO}
                          </div>
                          <div key={item.NUNOTA}>
                            <strong>Número Único</strong> {item.NUNOTA}
                          </div>
                          <div key={item.NUMNOTA}>
                            <strong>Número Nota</strong> {item.NUMNOTA}
                          </div>
                          <div key={dataEmissao}>
                            <strong>Data de Emissão</strong> {dataEmissao}
                          </div>
                          <div>
                            <strong>Valor</strong>{' '}
                            {moeda(Number(item.VLRNOTA))}
                          </div>

                          <div onClick={handlePropagation}>
                          <div>
                            <button className="btn btn-success" id="dropdownBtn" onClick={() => handlenotas(item)}>                                  
                                <FontAwesomeIcon icon={faPrint} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="whatsapp-fab">
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5519111111111"
          rel="noreferrer"
        >
          <img
            src={`${process.env.PUBLIC_URL}/whatsapp.png`}
            alt="Ícone de whatsapp para enviar uma mensagem"
          />
        </a>
      </div>
    </>
  );
};

export default Notas;
